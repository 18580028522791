  .coming-soon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to bottom right,#075a8d,#dfb9a2); /* Gradient background */
    /* background-image: url('/public/images/bg_03.jpg'); */

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .coming-soon-text {
    font-size: 8em; /* Large font size */
    font-weight: bold;
    text-transform: uppercase;
    margin: 0.1em;
    color: transparent; /* Transparent text color */
    background: linear-gradient(to bottom right, #f8a170, #8cd3ff); /* Same gradient as container */
    -webkit-background-clip: text; /* This clips the background to the text */
    background-clip: text;
  
    text-shadow: 
      4px 4px 0px rgba(0, 0, 0, 0.1), 
      8px 8px 0px rgba(0, 0, 0, 0.08),
      12px 12px 0px rgba(0, 0, 0, 0.05); /* Subtle 3D depth */
  }

  /* Tablet view */
@media (max-width: 1024px) {
    .coming-soon-text {
      font-size: 9em;  /* Adjusted font size */
    }
  }
  
  /* Mobile view */
  @media (max-width: 768px) {
    .coming-soon-text {
      font-size: 5.5em; /* Further adjusted font size */
    }
  }
  
  