.terms-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background: linear-gradient(to bottom right,#023b5f,#cb8c66); /* Gradient background */
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
}

.header-icon {
    display: flex;
    align-items: center; 
    justify-content: center;
    gap: 10px;
    color: #222;
    margin-bottom: 20px;
}

.terms-container h1 {
    margin-bottom: -56px;
    color: #222;
}

.terms-container p {
    margin-bottom: 15px;
}

.terms-container a {
    color: #0077cc;
    text-decoration: none;
}

.terms-container a:hover {
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .terms-container {
        padding: 1rem;
    }
}

@media only screen and (max-width: 600px) {
    .terms-container {
        padding: 0.5rem;
    }
}
