@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");


body {
    /* background-image: url('/public/images/car_08.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;  
    background-position: center center;  
    background-attachment: fixed;  
    /* background-color: #000; */
    /* background: linear-gradient(to bottom right,#075a8d,#dfb9a2);  */
    /* background-image: linear-gradient(to bottom right,#8babab, #b0b0b1cc); */
    background-image: linear-gradient(to bottom right, #041e32, #026596);

    font-family: 'Arial', sans-serif;
}
html, body {
    overflow-x: hidden;
  }

/* For Webkit browsers like Chrome, Safari */
::-webkit-scrollbar {
    width: 12px; /* Adjust as per your preference */
  }
  
  ::-webkit-scrollbar-track {
    background: #002b3b; /* Color of the scrollbar track */
  }
  
  ::-webkit-scrollbar-thumb {
    background-image: linear-gradient(to bottom right, #002b3b, #acc1d0); /* Gradient for the scrollbar thumb */
    border-radius: 6px; /* Rounded corners for the scrollbar thumb */
  }
  
  /* For Firefox */
  * {
    scrollbar-width: thin; /* You can use 'auto' or 'thin' */
    scrollbar-color: #f1f3f5 #002b3b; /* Thumb and track color */
  }
  
  


/* Card Styling */
.card {
    background:linear-gradient(to bottom right, #000080, #008080); 
    /* background-image: linear-gradient(to bottom right, #03719c, #013a63); */
    border: none;  
    transition: transform 0.3s, background-image 0.3s; /* Smooth transition for transform and background */
    /* transition: all 0.3s ease;  */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    border-radius: 12px;  
}

.card:hover {
    background-image: linear-gradient(to bottom right, #002b3b, #acc1d0);
    transform: scale(1.05); /* Scales the card to 105% of the original size */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* transform: translateY(-5px) scale(1.05); */
    /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);  */
}

.card-img-top {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;  
    transition: transform 0.3s ease;  
    height:140px;
}



.card-title {
    /* font-weight: bold; */
    color: #ecf0f1;  
    font-size:1em ;
    white-space: nowrap; /* This will ensure the text stays in one line */
    text-align: center;
    height:4px;
}
/* Target the anchor tags within your card and remove the underline */
.col-6 a, .col-md-4 a, .col-lg-2 a {
    text-decoration: none; /* Removes the underline from the link */
}

/* Ensure that the text-decoration is none even on hover */
.col-6 a:hover, .col-md-4 a:hover, .col-lg-2 a:hover {
    text-decoration: none; /* Ensures the underline does not reappear on hover */
}


.btn-primary {
    background-color: #e74c3c;  
    border: none; 
    transition: background-color 0.3s ease; 
}

.btn-primary:hover {
    background-color: #c0392b;  
}

/* Heading Styling */
h4 {
    border-bottom: 3px solid #e74c3c; 
    padding-bottom: 10px;
    margin-bottom: 20px;
    /* color: #ecf0f1; */
    color:white;
    font-weight: 600;
}

@media (max-width: 480px) {
    .card-img-top {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;  
        transition: transform 0.3s ease;  
        height:120px;
    }
}
