.ad-modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: flex;
  align-items: center;
  justify-content: center;
}

.ad-modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: auto;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensure positioning for the close button */
}

/* Style the close button */
.ad-modal-close {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7); /* Background for contrast */
  height: 30px;  /* Adjust the size */
  width: 30px;   /* Make it a perfect circle */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;  /* Circular shape */
  z-index: 9999;  /* Ensure it stays on top */
  border: none;  /* Remove any default border */
}

.ad-modal-close:hover,
.ad-modal-close:focus {
  color: #ff0000;  /* Change to red when hovered for more visibility */
  text-decoration: none;
  cursor: pointer;
}
