/* ContactUs.css */

.contact-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  /* background: #041e32 url('/public/images/Contactusgpt6.webp') no-repeat center center; */
  background-size: cover;
  position: relative;
  color: white;
  text-align: center;
}
  
  
  .contact-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    position: relative; /* Position relative to layer on top of the pseudo-element */
  }
  
  .contact-us-container::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: inherit; /* This will inherit the background image from .contact-us-container */
    background-size: cover;
    /* opacity: 0.02;  */
    z-index: -1; /* Ensures the pseudo element is behind the content */
  }
  
  .contact-details {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    gap: 10px;
    background: #026596;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
   h1 {
    /* color: #FFD700; */
    color: white;
    font-weight: bold;
    margin-top: -50px;
    margin-bottom: 40px;
    font-weight: 700;
}
  .contact-text {
    font-size: 1.5em;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .contact-content {
      flex-direction: column;
    }
  
    .contact-image {
      max-width: 80%;
    }
  }
  