  .about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background: linear-gradient(to bottom right,#023b5f,#cb8c66); /* Gradient background */
    background-size: cover;
    background-repeat: no-repeat;
    color: white; 
}
  
.about-container h1{
    color: #FFD700; 
    font-weight: bold;
    margin-top: 30px;
  }
  h2 {
    color: #FFD700; 
    font-weight: bold;
    margin-top: 30px;
  }
  
  p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 25px;
    text-align: justify;
  }
  

  /* Responsiveness for tablets */
@media screen and (max-width: 768px) {
    .about-container {
        padding: 1rem;
    }
}

/* Responsiveness for mobile phones */
@media screen and (max-width: 480px) {
    .about-container {
        padding: 0.5rem;
    }
}
  