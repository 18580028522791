.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-image: linear-gradient(to bottom right, #041e32, #026596);
  color: white;
  flex-wrap: wrap; /* Allow items to wrap as needed */
}

.footer-icons,
.footer-brand,
.footer-links {
  display: flex;
  align-items: center; /* Center align vertically */
  justify-content: center; /* Center align horizontally */
  flex: 1 1 100%; /* Allow each section to take full width on wrap */
}

.footer-icons {
  gap: 20px;
  margin-bottom: 10px; /* Only needed if there is no wrap */
}

.footer-icons > * {
  cursor: pointer;
  transition: transform 0.2s;
}

.footer-icons > *:hover {
  transform: scale(1.1);
}

.footer-brand {
  justify-content: center;
  order: 2; /* Position the brand in the middle */
}

.footer-links {
  gap: 10px;
  order: 3; /* Position the links at the end */
  flex-wrap: wrap; /* Allow link items to wrap */
}

.footer-links > a {
  color: white;
  text-decoration: none;
  transition: opacity 0.2s;
}

.footer-links > a:hover {
  /* opacity: 0.7; */
  color: #4DD7FF; /* Highlight color on hover */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .footer {
      flex-direction: column;
  }

  .footer-icons,
  .footer-brand,
  .footer-links {
      order: unset; /* Remove specific positioning */
      margin-bottom: 10px; /* Add spacing between wrapped items */
      flex: none; /* Do not allow flex grow or shrink */
  }

  .footer-brand {
      order: 1; /* Position brand back at the top */
  }
}
