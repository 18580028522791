.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 30px;
    cursor: pointer;
    z-index: 1000;
}

.scroll-to-top div {
    background: linear-gradient(to bottom right, #f8a170, #8cd3ff); /* Gradient background */
    color: black;
    padding: 5px;
    border-radius: 100%;
    display: none;   /* Set div to be hidden by default */
}

.scroll-to-top.show div {   /* New class to show the button */
    display: block;
}
