@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.navbar {
  /* background-color: #112c4b;  */
  background-image: linear-gradient(to bottom right, #041e32, #026596);

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}
.navbar-brand img {
  transition: transform 1.3s ease-in-out;
}

.navbar-brand:hover img {
  animation: rotate360 5.3s forwards;
}

/* .fa-bars {
  border: none;
  border-radius: 10%;
  font-size: 40px;
  color: whitesmoke;
} */
.fa-bars {
  font-size: 2rem; /* 2rem sized icon */
}

/* Ensures the icon has a smooth transition for color change or other effects */
.fa-bars {
  transition: color 0.3s ease-in-out;
}
.navbar-toggler {
  background-color: transparent; /* Ensures no background color */
  border: none; /* Removes the border */
  font-size: 35px; /* Larger font size */
  color: white; /* White color icon */
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  background-color: transparent; /* Maintains transparency on hover and focus */
  outline: none; /* Removes the outline on focus */
  box-shadow: none; /* Removes the box shadow on focus */
}
.navbar-toggler:hover .fa-bars {
  color: #e0e0e0; /* Lighter shade of white for a hover effect */
}
/* .navbar-toggler:focus {
  outline: none;
  box-shadow: none;

} */

/* .nav_ul li {
  padding-left: 30px;
  font-weight: 500;
  font-size: 26px;
  font-family: 'Jost', sans-serif;
  transition: color 0.3s;
} */
.nav_ul li {
  font-size: 20px; /* Increased font size */
  padding: 10px 20px; /* Top/Bottom, Left/Right padding for a square look */
  margin-right: 10px; /* Space between nav items */
  /* background-color: #2a4565;  */
  border-radius: 10px; 
  /* transition: background-color 0.3s, transform 0.3s;  */
}

/* .nav_ul li:hover {
  color: #f58614;
} */
.nav_ul li:hover {
  background-color: #03233b; 
}
.fa-bars {
  color: whitesmoke;
}
.fa {
  margin-right: 8px; /* Space between icon and text */
  font-size: 1.5em; /* Larger icon size */
}


 .dev {
  font-size: 20px; /* Increased font size */
  padding: 10px 20px; /* Top/Bottom, Left/Right padding for a square look */
  margin-right: 10px; /* Space between nav items */
  color: #fbf4f4;
  display: inline-block; 
}

.arcade {
  /* color: transparent; */
  background: linear-gradient(to bottom right, #17608d,#d8c3b6); 
  /* -webkit-background-clip: text;  */
  /* background-clip: text; */
  /* font-weight: 600; */
  border-radius: 10px; 
  color: #fff;

}

.shooting {
  /* color: transparent;  */
  /* background: linear-gradient(to bottom right, #8cd3ff,#f8a170);  */
  background: linear-gradient(to bottom right, #17608d,#d8c3b6); 

  /* -webkit-background-clip: text; */
  /* background-clip: text; */
  /* font-weight: 600; */
  border-radius: 10px; 
  color: #fff;

}

.puzzle {
  /* color: transparent;  */
  background: linear-gradient(to bottom right, #17608d,#d8c3b6); 
  /* -webkit-background-clip: text;  */
  /* background-clip: text; */
  /* font-weight: 600; */
  border-radius: 10px; 
  color: #fff;

}

.adventure {
  /* color: transparent;  */
  background: linear-gradient(to bottom right, #17608d,#d8c3b6); 
  /* -webkit-background-clip: text;  */
  /* background-clip: text; */
  /* font-weight: 600; */
  border-radius: 10px; 
  color: #fff;

}

.sports {
  /* color: transparent;  */
  background: linear-gradient(to bottom right, #17608d,#d8c3b6); 
  /* -webkit-background-clip: text;  */
  /* background-clip: text; */
  /* font-weight: 600; */
  border-radius: 10px; 
  color: #dedede;

}
span{
  margin-inline-end: 5px;
}

/* .arcade {
  color: #43f7f7;
}

.shooting {
  color: #dd6b60;
}

.puzzle {
  color: #51ff51;
}

.adventure {
  color: #eb9aff;
}

.sports {
  color: #FFFF00;
} */

.navbg {
  width: 100%;
  z-index: 10;
  max-width: 100%;
}

/* Navbar.css */

.nav-link {
  position: relative;
  margin: 0;  
  margin-bottom: 3px;  /* Reduced gap for each nav-link */
  padding: 0; /* Ensure no padding */
  display: flex;
  align-items: center;
  transition: color 0.3s ease; /* Smooth color transition */

}
.nav-link .fa {
  display: inline;
  color:red; /* Always show the icon */
}

.nav-link:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -2px;  /* you can adjust this as needed */
  height: 2px;
  background-color: currentColor; /* inherits the text color */
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.nav-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* .nav-link:hover {
  color: #4DD7FF;  
} */
.nav-link:hover {
  color: #4DD7FF; /* Highlight color on hover */
}

/* But we need to override this for the five colorful items to retain their specific hover effect. */
.arcade:hover,
.shooting:hover,
.puzzle:hover,
.adventure:hover,
.sports:hover {
  color: #FFFFFF;  /* White color */
}

.nav-item {
  margin: 0;  /* Clear any existing margins */
  margin-bottom: -1px; 
}


.games-heading {
  color: #FFFFFF;
  font-size: 14px;
  margin-bottom: 5px;
  display: none;
  font-weight: bold;
}



@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .games-heading {
      display: block; 
      
  }
}

@media only screen and (max-width: 600px) {
  .games-heading {
      display: block; 
  }
}
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .games-heading {
      font-size: 28px;  
  }
}

@media only screen and (max-width: 600px) {
  .games-heading {
      font-size: 26px;  
  }
}


/* Tablet View (601px to 1024px) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .nav_ul li, .nav-item.me-3 {
    font-size: 20px; 
    padding-left: 20px;
    margin-bottom: 3px;  
  }

  .dev {
    font-size: 21px;
    padding-left: 20px;
    margin-bottom: 3px;  /* Consistent bottom margin for tablet view */
  }
}


/* Mobile View (up to 600px) */
@media only screen and (max-width: 600px) {
  .nav_ul li, .nav-item.me-3 {
    /* font-size: 18px; */
    padding-left: 15px;
    margin-bottom: 3px;  /* Consistent bottom margin for mobile view */
  }

  .dev {
    font-size: 18px;
    padding-left: 15px;
    margin-bottom: 3px;  /* Consistent bottom margin for mobile view */
  }

  /* Adjust the top and bottom padding of the navbar to reduce excessive space */
  .navbar {
    padding-top: 3px;
    padding-bottom: 3px;
  }
}


/* Adjust the font size for mobile and tablet views */
@media only screen and (max-width: 1024px) {
  .nav_ul li, .nav-item.me-3 {
    font-size: 30px;
    margin-top: 2rem;
  }
  span{
    margin-inline-start: 10px;
  }
  .offcanvas-body {
    /* background: linear-gradient(to bottom right,#023b5f,#f9ccb0);  */
    background-image: linear-gradient(to bottom right, #041e32, #026596);
    color: #fbf4f4; /* Font color */
    
  }
  .offcanvas-header {
    background: linear-gradient(to bottom right, #041e32, #026596); /* Gradient background */
    color: #fbf4f4; 
  }
  .offcanvas-title{
    font-size: 2rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0.1em;
    color: transparent; /* Transparent text color */
    background: linear-gradient(to bottom right, #ffd2b8, #8cd3ff); /* Same gradient as container */
    -webkit-background-clip: text; /* This clips the background to the text */
    background-clip: text;
  
    text-shadow: 
      4px 4px 0px rgba(0, 0, 0, 0.1), 
      8px 8px 0px rgba(0, 0, 0, 0.08),
      12px 12px 0px rgba(0, 0, 0, 0.05); /* Subtle 3D depth */
  }
  .offcanvas {
    /* background-image: url('/public/images/bg_03.jpg'); */
    color: #fbf4f4; /* Font color */
    
  }

  .dev {
    font-size: 30px;
  }
}



/* ... Your existing code ... */



/* Apply the effect only for mobile and tab view */
@media only screen and (max-width: 1024px) {
  .nav-link::after {
    content: '';
    display: block;
    height: 4px;
    /* background: linear-gradient(45deg, #f58614, #f58614 100%); */
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
    margin-top: 5px;
    transform: perspective(5px) rotateX(1.5deg);
    transition: transform 0.3s;
  }

  .nav-link:hover::after {
    transform: perspective(5px) rotateX(0deg);
  }
}

.search-input {
  margin-left: 1rem; /* Adjust as necessary */
  padding: 0.5rem;
  border: 1px solid #ddd; /* Light gray border */
  border-radius: 5px;
  /* Other styles as necessary */
}

/* .search-icon {
  cursor: pointer;
  color: #fff; 
  font-size: 1.5em; 
  margin-left: 25px; 
  margin-top: 8px;
  margin-right: 10px;
  transition: transform 0.2s;
}

.search-icon:hover {
  transform: scale(1.1); 
} */
