.latest-games-wrapper {
  display: flex;
  width: 100%; /* Use the full width of the container */
  max-width: 1600px; /* You can increase this if you want a wider maximum width */
  margin: 0 auto; /* Center the wrapper */
  padding: 20px;
  gap: 20px; /* Space between the featured game and the latest games list */
}
.featured-game img {
  width: 100%;
  /* Set a fixed height for the images to match the container height */
  height: 500px; /* Match the height of the .featured-game container */
  object-fit: cover; /* This will cover the container with the image */
  border-radius: 10px;
}

.featured-game {
  flex: 0 0 70%; /* Use 70% of the available space */
  height: 500px; /* Fixed height */
  perspective: 1000px; 
  object-fit: cover;

}

.featured-game img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.5);
  animation: fadeIn 2s;
  transform: rotateY(0deg);
  transition: transform 0.5s ease-in-out
}
/* Hover effect for the featured image */
.featured-game img:hover {
  transform: rotateY(10deg); /* Slight 3D rotation on hover */
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes scaleUp {
  0% { transform: scale(0); }
  100% { transform: scale(1); }
}

/* Apply animation classes for each type of animation */
.fadeIn {
  animation: fadeIn 2s ease-in-out;
}

.scaleUp {
  animation: scaleUp 2s ease-in-out;
}
.latest-games-list {
  flex: 0 0 29%; 
  height: 440px; /* Match the height of the featured game */
}

.latest-games-list h2 {
  margin-top: -5px;
  margin-bottom: 15px;
  color: white;
  font-weight: 600;
  font-size: 24px;
  font-family: 'Jost', sans-serif;

}

.games-thumbnails {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 25px;
  height: 100%; 
  perspective: 500px;

}

.game-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 23px;
  background: #fff;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.5);
  transform: rotateY(0deg);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for the transform and box-shadow */
  will-change: transform, box-shadow; /* Optimize for properties that will change */
}

/* On hover, scale up the thumbnail and apply a larger shadow */
.game-thumbnail:hover {
  transform: scale(1.05) rotateY(10deg);
  box-shadow: 10px 15px 30px rgba(0, 0, 0, 0.5);
}
/* Pop-up animation when the thumbnail first appears */


.game-thumbnail img {
  width: 100%;
  height: 100%;
  max-height: 100%; /* Ensure the image fits well */
}

/* Tablet and small desktops */
@media (max-width: 1024px) {
  .featured-game {
    height: 450px; /* Maintain fixed height on tablets */
  }

  .latest-games-wrapper {
    flex-direction: column;
    gap: 10px;
}

  .featured-game, .latest-games-list {
      flex-basis: 100%;
      height: 400px; /* Adjust height for tablets */
  }
  

  .games-thumbnails {
      grid-template-columns: repeat(2, 1fr); /* Two columns for smaller tablets */
      gap: 15px; /* Adjust gap for smaller screens */
  }
  .latest-games-wrapper {
    flex-direction: column;
    gap: 10px;
}

.featured-game, .latest-games-list {
    flex-basis: 100%;
}

.games-thumbnails {
  display: flex; /* Changed from grid to flex for horizontal scrolling */
  overflow-x: auto; /* Allows horizontal scrolling */
  gap: 10px;
  padding-bottom: 10px; /* Adds space for scrollbar */
}

.game-thumbnail {
  flex: none; /* Removes flex sizing */
  width: calc((100% - 20px) / 2.3); /* Allows only 2.5 thumbnails to be shown at once */
  height: auto; /* Adjust height as necessary */
  margin-right: 10px; /* Adds a gap to the right of each thumbnail */
}
.latest-games-list h2 {
  margin-top: 13px;
  margin-bottom: 19px;
  font-size: 29px;
}
.game-thumbnail img {
  height:210px;
}
}


@media (max-width: 1024px), (max-width: 768px){
  .featured-game img {
    height: 400px;
  }
}


/* ... existing styles ... */
/* Landscape phones and smaller tablets */
@media (max-width: 768px) {
  .featured-game {
    height: 400px; /* Maintain fixed height on landscape phones */
}
  .latest-games-wrapper {
      flex-direction: column;
      gap: 10px;
  }

  .featured-game, .latest-games-list {
      flex-basis: 100%;
      height: 400px; /* Adjust height for landscape phones */
  }

  .games-thumbnails {
      grid-template-columns: 1fr; /* One column for landscape phones */
      gap: 15px;
  }
   .game-thumbnail img {
    height:180px;
  }
}

/* For landscape phones and smaller tablets */
@media (max-width: 768px) {
  .latest-games-wrapper {
      flex-direction: column;
      gap: 10px;
  }

  .featured-game, .latest-games-list {
      flex-basis: 100%;
  }

  .games-thumbnails {
    display: flex; /* Changed from grid to flex for horizontal scrolling */
    overflow-x: auto; /* Allows horizontal scrolling */
    gap: 10px;
    padding-bottom: 10px; /* Adds space for scrollbar */
  }

  .game-thumbnail {
    flex: none; /* Removes flex sizing */
    width: calc((100% - 20px) / 2.3); /* Allows only 2.5 thumbnails to be shown at once */
    height: auto; /* Adjust height as necessary */
    margin-right: 10px; /* Adds a gap to the right of each thumbnail */
  }
  .latest-games-list h2 {
    margin-top: 10px;
    margin-bottom: 14px;
    font-size: 24px;
  }
}

/* Portrait phones */
@media (max-width: 480px) {
  .featured-game {
    height: 230px; /* Maintain fixed height on portrait phones */
}
  .latest-games-wrapper {
      padding: 10px; /* Less padding for small screens */
  }

  .featured-game, .latest-games-list {
      height: 230px; /* Adjust height for portrait phones */
  }

  .games-thumbnails {
      gap: 15px; /* Adjust gap for small screens */
  }

 
}
/* For portrait phones */
@media (max-width: 480px) {
  .featured-game {
      height: 230px; /* Adjusted fixed height */
  }

  .game-thumbnail {
    width: calc((100% - 20px) / 2.3); /* Adjusts width for only 2.5 thumbnails to show */
  }
  .game-thumbnail img {
    height:120px;
  }
  .games-thumbnails {
    gap: 10px; /* Ensures consistency in gap */
  }
  .latest-games-list h2 {
    margin-top: 10px;
    margin-bottom: 12px;
    font-size: 24px;
  }
}
@media (max-width: 480px) {
  .featured-game img {
    height: 220px; /* Keep the fixed height for responsive design */
    border-radius: 12px;

  }
}

/* ... scrollbar hide ... */

/* Responsive styles */
@media (max-width: 1024px) {

  .games-thumbnails {
    overflow-x: auto; 
  }
}

/* Hiding the scrollbar for the games thumbnails container */
.games-thumbnails::-webkit-scrollbar {
  display: none;
}

.games-thumbnails {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}



