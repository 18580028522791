.privacy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    background: linear-gradient(to bottom right,#023b5f,#cb8c66); /* Gradient background */
    background-size: cover;
    background-repeat: no-repeat;
    color: white;
  }
  
  .header-icon {
    display: flex;
    align-items: center; 
    justify-content: center;
    gap: 10px;
    color: #222;
    margin-bottom: 20px;
  }
  
 
  
  .privacy-container h1 {
    margin-bottom: -56px;
    color: #222;
  }
  
  .privacy-container p {
    margin-bottom: 15px;
  }
  
  .privacy-container a {
    color: #0077cc;
    text-decoration: none;
  }
  
  .privacy-container a:hover {
    text-decoration: underline;
  }
  
  @media only screen and (max-width: 768px) {
    .privacy-container {
      padding: 1rem;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .privacy-container {
      padding: 0.5rem;
    }
  }
  