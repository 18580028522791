/* RecentlyPlayed.css */

/* Container to match Games.js */
.recently-played {
    padding-left: 25px; /* Adjust padding to match Games.js layout */
  }
  
  .recently-played h2 {
    /* Styling for the section header, make sure it matches Games.js */
    /* border-bottom: 3px solid #e74c3c; */
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: white;
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    font-family: 'Jost', sans-serif;
  }
  
  /* This makes the cards wrap and have space between them like in Games.js */
  .recently-played .row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: flex-start;
  }
  
  /* Use the same card style from Games.css */
  .recently-played .card {
    /* Copy the .card styles from Games.css */
    background: linear-gradient(to bottom right, #000080, #008080);
    border: none;
    transition: transform 0.3s, background-image 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
  }
  
  .recently-played .card:hover {
    /* Copy the .card:hover styles from Games.css */
    background-image: linear-gradient(to bottom right, #002b3b, #acc1d0);
    transform: scale(1.05);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  
  
  /* ... other media queries and styles ... */

  /* Responsive styling, adjust the breakpoints as needed */
  @media (max-width: 1024px) {
    .recently-played .row {
        flex-wrap: nowrap; /* Prevent wrapping of items */
        overflow-x: auto; /* Enable horizontal scrolling */
        /* gap:40px; */
        justify-content: flex-start; /* Align items to the start of the container */
        /* Remove margins if previously set to ensure full width */
      }
    
      .recently-played .card {
        flex: 0 0 auto; /* Do not grow, do not shrink, automatic basis */
        width: calc(100vw /3.3); /* Show 2.2 cards, subtract the gap from the width */

        /* Adjust any other card styles as needed */
      } 
      .recently-played h2 {
        font-size: 30px;
      }
     }
  
  @media (max-width: 768px) {
    .recently-played .row {
        flex-wrap: nowrap; /* Prevent wrapping of items */
        overflow-x: auto; /* Enable horizontal scrolling */
        /* gap:10px; */
        justify-content: flex-start; /* Align items to the start of the container */
        /* Remove margins if previously set to ensure full width */
      }
    
      .recently-played .card {
        flex: 0 0 auto; /* Do not grow, do not shrink, automatic basis */
        width: calc(100vw /3); /* Show 2.2 cards, subtract the gap from the width */
        /* Adjust any other card styles as needed */
      } 
   
    }
  
  /* ... existing styles ... */

@media (max-width: 480px) {
    .recently-played .row {
      flex-wrap: nowrap; /* Prevent wrapping of items */
      overflow-x: auto; /* Enable horizontal scrolling */
      gap:5px;
      justify-content: flex-start; /* Align items to the start of the container */
      /* Remove margins if previously set to ensure full width */
    }
  
    .recently-played .card {
      flex: 0 0 auto; /* Do not grow, do not shrink, automatic basis */
      width: calc(100vw / 2.5); /* Show 2.2 cards, subtract the gap from the width */
      /* Adjust any other card styles as needed */
    }
    .recently-played h2 {
        font-size: 24px;
      }
      .recently-played {
        padding-left: 13px; /* Adjust padding to match Games.js layout */
      }
  }

/* RecentlyPlayed.css */

/* ... existing styles ... */

/* Hide scrollbar for all browsers */
.recently-played .row {
    /* ... existing styles ... */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  
  /* Hide scrollbar for Chrome, Safari, and Opera */
  .recently-played .row::-webkit-scrollbar {
    display: none;
  }
  
  /* ... other media queries and styles ... */
  
  

  